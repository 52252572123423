import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slice from "../components/slice"

const PageTemplate = ({ data }) => {
  const { title, page_description, body } = data.prismicPage.data
  return (
    <Layout>
      <SEO title={title.text} description={page_description} />
      <div className="max-w-6xl mx-auto px-4 my-8 w-full">
        <h1 className="mt-4 mb-2 text-center">{title.text}</h1>
      </div>
      {body.map((slice, i) => (
        <Slice {...slice} key={i} />
      ))}
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query PageByUid($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        page_description
        body {
          ... on PrismicPageBodyRichtext {
            primary {
              richtext {
                html
              }
            }
            slice_type
          }
          ... on PrismicPageBodyImage {
            slice_type
            primary {
              image {
                fluid(maxWidth: 1440) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
          ... on PrismicPageBodyMapText {
            slice_type
            primary {
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`
